import React from 'react'
import { SearchContextProvider } from './src/components/hooks/useSearchContext'
import { SelectedPropertyContextProvider } from './src/components/hooks/useSelectedPropertyContext'
import { UserDataContextProvider } from './src/components/hooks/useUserDataContext'
import { ExecutiveStateContextProvider } from './src/components/hooks/useExecutiveStateContext'

export const wrapRootElement = ({ element }) => {

  return (
    <SearchContextProvider>
      <SelectedPropertyContextProvider>
        <UserDataContextProvider>
          <ExecutiveStateContextProvider>
          {element}
          </ExecutiveStateContextProvider>
        </UserDataContextProvider>
      </SelectedPropertyContextProvider>
    </SearchContextProvider>
  )
}