import React, { useContext, useState } from 'react'

const SearchContext = React.createContext()
const UpdateSearchContext = React.createContext()

export function useSearch() {
    return useContext(SearchContext)
}

export function useUpdateSearch() {
    return useContext(UpdateSearchContext)
}

export function SearchContextProvider({ children }) {

    const [currentSearch, setCurrentSearch ] = useState({
                                                    "city": null,
                                                    "gender": null,
                                                    "roomType": null,
                                                    "bedCapacity": null,
                                                    "bathroomCapacity": null,
                                                    "minAmount": null,
                                                    "maxAmount": null
                                                });
    
    return (
        <SearchContext.Provider value = {currentSearch}>
            <UpdateSearchContext.Provider value = {setCurrentSearch}>
                {children}
            </UpdateSearchContext.Provider>
        </SearchContext.Provider>
    )
}