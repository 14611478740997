import React, { useContext, useState } from 'react'

const ExecutiveStateContext = React.createContext()
const UpdateExecutiveStateContext = React.createContext()

export function useExecutiveState() {
    return useContext(ExecutiveStateContext)
}

export function useUpdateExecutiveState() {
    return useContext(UpdateExecutiveStateContext)
}

export function ExecutiveStateContextProvider({ children }) {

    const [executiveState, setExecutiveState ] = useState();
    
    return (
        <ExecutiveStateContext.Provider value = {executiveState}>
            <UpdateExecutiveStateContext.Provider value = {setExecutiveState}>
                {children}
            </UpdateExecutiveStateContext.Provider>
        </ExecutiveStateContext.Provider>
    )
}
