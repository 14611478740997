import React, { useContext, useState } from 'react'

const UserDataContext = React.createContext()
const UpdateUserDataContext = React.createContext()

export function useUserData() {
    return useContext(UserDataContext)
}

export function useUpdateUserData() {
    return useContext(UpdateUserDataContext)
}

export function UserDataContextProvider({ children }) {

    const [userData, setUserData ] = useState();

    // const [userData, setUserData ] = useState({
    //     title: 'mr',
    //     firstName: 'Sheen',
    //     surname: 'Jocson',
    //     email: 'sheen@studiomalt.com.au',
    //     phone: '987654321',
    //     schoolType: 'school',
    //     schoolName: 'Test School',
    //     specialCircumstances: 'Nothing',
    //     selectedRoomID: 2083,
    //     selectedRoomDescription: '03 South Ridge Rd'
    // });
    
    return (
        <UserDataContext.Provider value = {userData}>
            <UpdateUserDataContext.Provider value = {setUserData}>
                {children}
            </UpdateUserDataContext.Provider>
        </UserDataContext.Provider>
    )
}
