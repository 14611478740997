import React, { useContext, useState } from 'react'

const SelectedPropertyContext = React.createContext()
const UpdateSelectedPropertyContext = React.createContext()

export function useSelectedProperty() {
    return useContext(SelectedPropertyContext)
}

export function useUpdateSelectedProperty() {
    return useContext(UpdateSelectedPropertyContext)
}

export function SelectedPropertyContextProvider({ children }) {

    const [currentSelectedProperty, setCurrentSelectedProperty ] = useState( {
            roomTypeID: null,
            roomID: null
        });
    
    return (
        <SelectedPropertyContext.Provider value = {currentSelectedProperty}>
            <UpdateSelectedPropertyContext.Provider value = {setCurrentSelectedProperty}>
                {children}
            </UpdateSelectedPropertyContext.Provider>
        </SelectedPropertyContext.Provider>
    )
}